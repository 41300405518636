import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 83",
  week: "Semana 12",
  day: "04",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-04",
  path: "/cronologia/semana-12#dia-04-jun/",
};

const Day83 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModCovidHighlight>
          Pakistán con 85.000 casos, supera el registro de China
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModDailyHighlight>
          Noveno día del periodo de luto oficial
        </ModDailyHighlight>
        <ModText>
          En los últimos siete días se han diagnosticado 2,276 casos mediante
          pruebas PCR. En ese mismo periodo, 166 personas han requerido
          hospitalización (9,3% de los diagnósticos), de los que 11 han sido
          ingresos en la UCI, y se han producido 56 fallecimientos.
        </ModText>
        <ModText>
          El Ministerio de Consumo ha puesto en marcha{" "}
          <InlineLink link="http://consumidorescovid19.gob.es/">
            una página web
          </InlineLink>{" "}
          para asistir a consumidores y usuarios afectados durante el estado de
          alarma que necesiten información sobre sus derechos, una vez que los
          procedimientos administrativos se han reactivado.
        </ModText>

        <ModText>
          Los{" "}
          <InlineLink link="http://www.culturaydeporte.gob.es/cultura/museos/destacados/2020/abrimos-de-nuevo-museos.html">
            museos estatales
          </InlineLink>{" "}
          gestionados por el Ministerio de Cultura y Deporte abrirán al público
          el próximo martes, 9 de junio, en su horario habitual y con entrada
          gratuita hasta el 31 de julio. Se permitirá la visita individual o por
          unidad familiar a la colección permanente y a las exposiciones
          temporales.
        </ModText>
        <ModImage
          src="/images/svg/22_mar_mascarillas_sanitarios.svg"
          alt="sanitarios"
        />

        <ModText>
          Los <strong>sanitarios españoles</strong> que trabajan en primera
          línea contra COVID-19 han sido galardonados hoy con el{" "}
          <strong>Premio Princesa de Asturias de la Concordia 2020</strong>,
          según ha hecho público este miércoles el jurado encargado de su
          concesión.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day83;
